import React, { createContext, useState, useEffect, useContext } from "react";
import { Get } from "../redux/features/API";

// Create a Context
const AdsContext = createContext();

// Create a Provider component
export const AdsProvider = ({ children }) => {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  useEffect(() => {
    const fetchAds = async () => {
      setLoading(true);
      try {
        const response = await Get({
          url: "/public/advertisement/get-advertisement",
        }); // Adjust the URL as needed
        // console.log(JSON.stringify(response));
        const filteredResponse = response
          .sort((a, b) => b.id - a.id); // Sorting in descending order by id

        setAds(filteredResponse);
      } catch (error) {
        console.error("Error in ads fetch: " + error);
        setAds([]); // Reset ads in case of error
      } finally {
        setLoading(false);
      }
    };

    fetchAds(); // Always fetch the latest ads
  }, [lge]); // Run this effect when the language changes

  return (
    <AdsContext.Provider value={{ ads, loading, setAds }}>
      {children}
    </AdsContext.Provider>
  );
};

// Custom hook for using the context
export const useAds = () => useContext(AdsContext);
