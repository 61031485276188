import React, { useState, useEffect } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import AdvertisementModify from "./Buttons/AdvertisementModify";
import { Get, Delete } from "../../../redux/features/API";
import { useNewsSearch } from "../../../Dashboard/searchNewsContext";

const AdvertisementTable = ({ reload, setReload }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const [ifContent, setIfContent] = useState(false);
  const [largeGifUrl, setLargeGifUrl] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };
  const { searchValue } = useNewsSearch();

  const fetchAdvertisements = async () => {
    setLoading(true); // Start loading
    try {
      const hasContent = searchValue && /\S/.test(searchValue);
      setIfContent(hasContent);
      const url = hasContent
        ? `/search/search/?q=${searchValue}`
        : "/advertisement/advertisement";

      const response = await Get({
        url,
        headers: hasContent ? undefined : headers,
      });

      const responseData = hasContent
        ? response.advertisement || []
        : response || [];

      if (!responseData || (hasContent && !response.advertisement)) {
        throw new Error("Invalid response structure");
      }

      const sortedResponse = responseData
        .map((ad) => ({ ...ad }))
        .sort((a, b) => b.id - a.id);

      setDataSource(
        sortedResponse.map((ad, index) => ({ ...ad, key: index + 1 }))
      );
    } catch (error) {
      message.error("Error fetching advertisements.");
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchAdvertisements();
    setReload(false);
  }, [reload, searchValue]);

  const showEditModal = (record) => {
    setSelectedAd(record);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const handleCancelEditModal = () => {
    setIsModalOpen(false);
    setSelectedAd(null);
  };

  const handleDelete = (adId) => {
    setAdToDelete(adId);
    setIsConfirmDeleteVisible(true);
  };

  const confirmDelete = async () => {
    try {
      await Delete({
        url: `/advertisement/advertisement/${adToDelete}`,
        headers,
      });
      message.success("Advertisement deleted successfully.");
      setIsConfirmDeleteVisible(false);
      setAdToDelete(null);
      await fetchAdvertisements();
    } catch (error) {
      message.error("Error deleting advertisement.");
    }
  };

  const handleGifClick = (imageUrl, record) => {
    setLargeGifUrl(imageUrl);
    setSelectedAd(record);
    setIsModalOpen(false);
  };

  const handleGifPreviewCancel = () => {
    setLargeGifUrl(null);
  };

  const columns = [
    {
      title: "S.N",
      dataIndex: "key",
    },
    {
      title: "Title",
      dataIndex: "ads_name",
    },
    {
      title: "Image",
      dataIndex: "ads_image",
      render: (image, record) => (
        <img
          src={ifContent ? `https://cms.krishisanjal.com${image}` : `${image}`}
          alt="Advertisement"
          style={{ width: 100, cursor: "pointer" }}
          onClick={() => handleGifClick(image, record)}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Button
            type="primary"
            onClick={() => showEditModal(record)}
            className="bg-white text-black"
          >
            <EditOutlined />
          </Button>
          <Button type="danger" onClick={() => handleDelete(record.id)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Modify Advertisement"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancelEditModal}
        footer=""
        okButtonProps={{
          style: { color: "black", border: "1px solid #bdbbbb" },
        }}
      >
        <AdvertisementModify
          setReload={setReload}
          handleCancel={handleCancelEditModal}
          selectedAd={selectedAd}
        />
      </Modal>

      <Modal
        title="GIF Preview"
        visible={!!largeGifUrl}
        footer={null}
        onCancel={handleGifPreviewCancel}
        width={800}
      >
        {largeGifUrl && (
          <img src={largeGifUrl} alt="Large GIF" style={{ width: "100%" }} />
        )}
      </Modal>

      <Modal
        title="Confirm Deletion"
        visible={isConfirmDeleteVisible}
        onOk={confirmDelete}
        onCancel={() => setIsConfirmDeleteVisible(false)}
        okText="Yes"
        cancelText="No"
        okButtonProps={{ style: { backgroundColor: "blue" } }}
      >
        <p>Are you sure you want to delete this advertisement?</p>
      </Modal>

      <Table
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        loading={loading} // Add loading prop here
      />
    </>
  );
};

export default AdvertisementTable;
