import React, { createContext, useState, useEffect, useContext } from "react";
import { Get } from "../redux/features/API"; // Adjust the import as necessary

// Create a Context
const searchNewsContext = createContext();

// Create a Provider component
export const NewsSearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <searchNewsContext.Provider value={{ searchValue, setSearchValue }}>
      {children}
    </searchNewsContext.Provider>
  );
};

// Custom hook for using the context
export const useNewsSearch = () => useContext(searchNewsContext);
