import React, { useEffect, useState } from "react";
import Ads from "../../common/cards/Ads";
import logo from "./logo.ico";
import Share from "./Share";
import Contact from "../../common/cards/newscard/sideBarComponents/Contact";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import { useParams } from "react-router-dom";
import { useNews } from "../../../Dashboard/NewsContext";
import { Skeleton } from "antd";
import AuthorBredCrumb from "../../common/cards/AuthorBredCrumb";
import SmallAds from "../../common/cards/SmallAds";
import { useTheme } from "../../../Dashboard/ThemeContext";
import RoadBlocking from "../../common/cards/RoadBlocking";
import FormatNepaliDate from "../../../redux/Specials/FormatNepaliDate";
import FormatEnglishDate from "../../../redux/Specials/FormatEnglishDate";
import { useCount } from "../../../Dashboard/CountContext";
import { Get } from "../../../redux/features/API";
import NotFound from "../ErrorPage/NotFound";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Story = () => {
  const { newsId } = useParams();
  const { wholeNews, loading: newsLoading } = useNews();
  const { themeColor, bgColor } = useTheme();
  const { count } = useCount();
  const [scrolled, setScrolled] = useState(false);
  const [news, setNews] = useState(null);
  const [nepaliDate, setNepaliDate] = useState("");
  const [englishDate, setEnglishDate] = useState("");
  const [viewsId, setViewsId] = useState(null);
  const [shareCount, setShareCount] = useState(0);
  const newsUrl = window.location.href;
  const loading = newsLoading || !wholeNews;

  useEffect(() => {
    if (!wholeNews) return;

    const filteredNews = wholeNews.find((item) => item.id === Number(newsId));
    if (filteredNews) {
      setNews(filteredNews);
      setNepaliDate(FormatNepaliDate(filteredNews.self_date));
      setEnglishDate(FormatEnglishDate(filteredNews.self_date));
    } else {
      setNews(null);
    }
  }, [wholeNews, newsId]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchAndPostViews = async () => {
      if (!news) return;

      try {
        const response = await count;
        const filteredResponse = response.find(
          (item) => item.title === String(newsId)
        );
        if (filteredResponse) {
          const response2 = await Get({
            url: `/count/posts/${filteredResponse.id}/`,
          });
          if (response2) {
            setShareCount(JSON.parse(response2.shares[0].share_count));
            setViewsId(response2.id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAndPostViews();
  }, [count, newsId, news]);

  const renderHtmlContent = (htmlString) => (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlString || "<p>No content to display.</p>",
      }}
      className="content"
      style={{ lineHeight: "1.6", wordWrap: "break-word" }}
    />
  );

  if (loading) {
    return (
      <div className="w-[90%] mx-auto my-[50px] flex flex-col gap-[30px]">
        <Skeleton active paragraph={{ rows: 4 }} />
        <Skeleton active paragraph={{ rows: 4 }} />
        <Skeleton active paragraph={{ rows: 4 }} />
        <Skeleton active paragraph={{ rows: 4 }} />
        <Skeleton active paragraph={{ rows: 4 }} />
      </div>
    );
  }

  if (!news && wholeNews && !loading) {
    return <NotFound />;
  }

  return (
    <HelmetProvider>
      <div
        className="flex justify-center w-full"
        style={{ backgroundColor: bgColor }}
      >
        {news && (
          <Helmet>
            <link rel="icon" href={logo} type="image/x-icon" />
            <link rel="apple-touch-icon" href={logo} />
            <title>{news.news_title}</title>
            <meta property="og:type" content="article" />
            <meta property="og:description" content={news.news_title} />
            {/* <meta property="og:image" content={news.image} /> */}
            <meta property="og:image" content={news.image} />
            <meta property="og:image:height" content="800" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:title" content={news.news_title} />
            <meta property="og:url" content={newsUrl} />
            <meta name="twitter:title" content={news.news_title} />
            <meta name="twitter:image" content={news.image} />
            <meta name="twitter:description" content={news.news_title} />
          </Helmet>
        )}
        <div className="flex flex-col justify-center w-[97%] sm:w-[90%]">
          <RoadBlocking name="S_roadblocking_ads" />
          <Ads name="S_landscape_before_title" />

          <h2
            className={`text-${scrolled ? "2xl pl-3 md:text-3xl shadow-lg" : "3xl md:text-5xl"} 
          duration-[1s] font-bold sticky top-[59px] z-10 p-2`}
            style={{
              lineHeight: "1.5",
              transition: "font-size 0.5s ease-in-out",
              backgroundColor: bgColor,
            }}
          >
            {news && <h1>{news.news_title}</h1>}
          </h2>

          <div className="flex flex-col w-full items-center gap-12 py-4 mt-8 mb-5">
            <div className="w-full flex flex-wrap justify-between sm:px-5">
              <h1 className="flex gap-5 items-center text-center ">
                {englishDate && nepaliDate && news.author_name && (
                  <AuthorBredCrumb
                    id={news.author_name}
                    englishDate={englishDate}
                    nepaliDate={nepaliDate}
                    category={news.category_name}
                    language={news.language}
                  />
                )}
              </h1>
              <span className="flex gap-[15px] justify-end w-full lg:w-auto ">
                <Share
                  newsTitle={news.news_title}
                  id={viewsId}
                  shareCount={shareCount}
                />
              </span>
            </div>
          </div>

          <Ads name="S_landscape_after_title" />
          <div className="w-full grid grid-cols-11">
            <div className="col-span-11 xl:col-span-7 w-full h-full">
              <div className="flex flex-col gap-[20px] w-full">
                {news.image && (
                  <img
                    src={news.image}
                    alt="News"
                    style={{
                      border: `2px dotted ${themeColor}`,
                      borderRadius: "5px",
                    }}
                    className="w-full"
                  />
                )}
                <div style={{ backgroundColor: bgColor, width: "100%" }}>
                  {renderHtmlContent(news.news_post)}
                </div>
                {news.table_html && renderHtmlContent(news.table_html)}
              </div>
              <div className="my-5">
                <Share
                  newsTitle={news.news_title}
                  id={newsId}
                  shareCount={shareCount}
                />
              </div>
              <div className="sticky top-[120px]">
                <Ads name="S_landscape_after_content" />
              </div>
            </div>
            <div className="col-span-11 xl:col-span-4 h-full px-5">
              <div>
                <SmallAds name="S_sidebar_before_followus1" />
                <SmallAds name="S_sidebar_before_followus2" />
                <h2 className="text-2xl font-bold">Follow Us:</h2>
                <Contact />
                <SmallAds name="S_sidebar_after_followus1" />
                <SmallAds name="S_sidebar_after_followus2" />
              </div>
              <div style={{ position: "sticky", top: "60px", zIndex: "5" }}>
                <TajaSamachar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Story;
