// import React from "react";

// export default function Table() {
//   return (
//     <div style={{ backgroundColor: "#7acf7e" }}>
//       <h1
//         className="font-bold"
//         style={{
//           textAlign: "center",
//           fontSize: "2.5rem",
//           margin: "0 0 20px 0",
//         }}
//       >
//         Kalimati Vegitable and Fruits Rate Today
//       </h1>
//       <hr
//         style={{
//           flex: 1,
//           border: "none",
//           borderTop: "2px solid black",
//         }}
//       />
//       <h1
//         className="font-bold"
//         style={{
//           textAlign: "center",
//           fontSize: "1.5rem",
//           margin: "0 0 20px 0",
//         }}
//       >
//         Daily prices - July 29, 2024 A.D.
//       </h1>
//       <table style={{ width: "100%" }}>
//         <thead style={{ backgroundColor: "#f0efa1", height: "40px" }}>
//           <th className="text-lg">
//             Commodity <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Unit <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Minimum <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Maximum <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//           <th className="text-lg">
//             Average <ion-icon name="swap-vertical-outline"></ion-icon>
//           </th>
//         </thead>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//         <tbody style={{ backgroundColor: "#f5f4da", height: "40px" }}>
//           <td>Tomato Big(Nepali)</td>
//           <td>Kg</td>
//           <td>70</td>
//           <td>80</td>
//           <td>75</td>
//         </tbody>
//       </table>
//     </div>
//   );
// }
import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function Table() {
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );
  return (
    <HelmetProvider>
      <div className="flex justify-center w-full mt-10">
        <Helmet>
          <title>{lge === "en" ? "Table" : "बजार"} | Krishi Sanjal-Login</title>
          <meta
            property="og:description"
            content="Krishi Sanjal Dashboard page"
          />
          <meta
            property="og:title"
            content="KrishiSanjal - Empowering Farmers"
          />

          <meta
            property="og:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />

          <meta property="fb:app_id" content="1090711906078716" />

          <meta property="og:url" content="https://krishisanjal.com" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="KrishiSanjal - Empowering Farmers"
          />
          <meta
            name="twitter:description"
            content="Providing agricultural resources to farmers across Nepal."
          />
          <meta
            name="twitter:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />
        </Helmet>
        <div className="w-[97%] sm:w-[90%] h-[100vh] overflow-scroll ">
          <iframe
            src="https://nepalicalendar.rat32.com/vegetable/embed.php"
            frameBorder="0"
            scrolling="no"
            style={{
              border: "none",
              overflow: "hidden",
              width: "100%",
              height: "3000px", // Ensure height is sufficient for content
              borderRadius: "5px",
              padding: "0",
              margin: "0",
            }}
            allowTransparency="true"
          ></iframe>
        </div>
      </div>
    </HelmetProvider>
  );
}
