import React, { useEffect, useState } from "react";
import Ads from "../../../Ads";
import Breadcrumb from "../../../../navigation/Breadcrumb";
import BigCardContentRight from "../../BigCardContentRight";
import SmallCardContentBottom from "../../SmallCardContentBottom";
import TrendingNews from "../TrendingNews";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { Button } from "antd";
import TajaSamachar from "../TajaSamachar";
import { useNews } from "../../../../../../Dashboard/NewsContext";
import { useTheme } from "../../../../../../Dashboard/ThemeContext";
import { Get } from "../../../../../../redux/features/API";
import NotFound from "../../../../../page/ErrorPage/NotFound";
import { Spin } from "antd";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const { wholeNews, loading } = useNews();
  const [filteredNews, setFilteredNews] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const { bgColor } = useTheme();
  const [isValidCategory, setIsValidCategory] = useState(null); // Track valid category state

  // Function to check if the category is valid
  const checkCategoryValidity = async () => {
    try {
      // Get the category data directly
      const categoryData = await Get({
        url: "/public/category/get-category",
      });
      // console.log("categoryData:", categoryData);

      // Get the subcategory data directly
      const subCategoryData = await Get({
        url: "/public/category-key/get-categoryKey",
      });
      // console.log("subCategoryData:", subCategoryData);

      // Use find to check if category or subcategory matches
      const isCategoryValid = categoryData.find(
        (item) => item.category_name === categoryName
      );
      const isSubCategoryValid = subCategoryData.find(
        (item) => item.category_key_name === categoryName
      );

      setIsValidCategory(!!(isCategoryValid || isSubCategoryValid)); // Convert to boolean
    } catch (error) {
      console.error("Error fetching category data:", error);
      setIsValidCategory(false); // Assume invalid on error
    }
  };

  useEffect(() => {
    checkCategoryValidity();
  }, [categoryName]);

  useEffect(() => {
    if (wholeNews.length) {
      const categoryNews = wholeNews.filter(
        (item) =>
          item.category_name === categoryName ||
          item.sub_category === categoryName
      );
      // console.log(categoryNews);
      setFilteredNews(categoryNews);
    }
  }, [wholeNews, categoryName]);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  if (loading) {
    return (
      <div
        className="w-full flex justify-center"
        style={{ backgroundColor: bgColor }}
      >
        <div className="w-[97%] sm:w-[90%]">
          <div className="w-full grid grid-cols-6 mt-10">
            <div className="col-span-6 md:col-span-4 px-3">
              <Breadcrumb
                showLinks={false}
                myWord={categoryName}
                addNews={false}
              />
              <Skeleton variant="rectangular" width="80%" height="60vh" />
              <div className="w-full flex flex-wrap gap-[25px]">
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
              </div>
            </div>
            <div className="col-span-6 md:col-span-2 mt-10">
              <div className="sticky top-[60px]">
                <TajaSamachar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isValidCategory === null) {
    // Still checking category validity, show loading or a placeholder
    return (
      <div
        className="w-full flex justify-center"
        style={{ backgroundColor: bgColor }}
      >
        <div className="w-[97%] sm:w-[90%]">
          <div className="w-full grid grid-cols-6 mt-10">
            <div className="col-span-6 md:col-span-4 px-3">
              <Breadcrumb
                showLinks={false}
                myWord={categoryName}
                addNews={false}
              />
              <Skeleton variant="rectangular" width="80%" height="80vh" />
              <div className="w-full flex flex-wrap gap-[25px]">
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
                <Skeleton variant="rectangular" width="80%" height={150} />
              </div>
            </div>
            <div className="col-span-6 md:col-span-2 mt-10">
              <div className="sticky top-[60px]">
                <TajaSamachar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isValidCategory === false) {
    // Invalid category or subcategory, show NotFound
    return <NotFound />;
  }

  // If no news found but valid category/subcategory, show "No News Found"
  if (filteredNews.length === 0) {
    return (
      <div className="text-center text-lg font-semibold">No News Found</div>
    );
  }

  // Render the main category content
  return (
    <HelmetProvider>
      {isValidCategory && (
        <Helmet>
          <title>{categoryName} | Krishi Sanjal-Login</title>
          <meta
            property="og:description"
            content="Krishi Sanjal Dashboard page"
          />
          <meta
            property="og:title"
            content="KrishiSanjal - Empowering Farmers"
          />

          <meta
            property="og:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />

          <meta property="fb:app_id" content="1090711906078716" />

          <meta property="og:url" content="https://krishisanjal.com" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="KrishiSanjal - Empowering Farmers"
          />
          <meta
            name="twitter:description"
            content="Providing agricultural resources to farmers across Nepal."
          />
          <meta
            name="twitter:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />
        </Helmet>
      )}

      <div
        className="w-full flex justify-center"
        style={{ backgroundColor: bgColor }}
      >
        <div className="w-[97%] sm:w-[90%]">
          <div className="w-full grid grid-cols-6 mt-10">
            <div className="col-span-6 md:col-span-4 px-3">
              <Breadcrumb
                showLinks={false}
                myWord={categoryName}
                addNews={false}
              />

              <div className="mt-4">
                <BigCardContentRight
                  showParagraph={true}
                  id={filteredNews[0].id}
                  title={filteredNews[0].news_title}
                  sub_title={filteredNews[0].news_sub_title}
                  image={filteredNews[0].image}
                  created_date_ad={filteredNews[0].created_date_ad}
                  created_date_bs={filteredNews[0].created_date_bs}
                />
                <div className="flex flex-wrap justify-evenly gap-[15px] sm:gap-[30px] mt-4">
                  {filteredNews.slice(1, visibleCount).map((item) => (
                    <div
                      key={item.id}
                      className="w-[95%] sm:w-[80%] xl:w-[40%] pb-4 pt-2 px-3 bg-green-100 rounded-md"
                    >
                      <SmallCardContentBottom
                        lineClampTitle={1}
                        lineClampDes={2}
                        textBlack={true}
                        showParagraph={true}
                        showDate={false}
                        title={item.news_title}
                        sub_title={item.news_sub_title}
                        image={item.image}
                        id={item.id}
                        created_date_ad={item.created_date_ad}
                        created_date_bs={item.created_date_bs}
                      />
                    </div>
                  ))}
                </div>
                {visibleCount < filteredNews.length && (
                  <Button
                    onClick={handleLoadMore}
                    type="primary"
                    className="mt-4 w-full bg-green-500 hover:bg-green-100 text-white font-bold"
                  >
                    Load More
                  </Button>
                )}
              </div>
            </div>
            <div className="col-span-6 md:col-span-2 mt-10">
              <div className="sticky top-[60px]">
                <TajaSamachar />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default CategoryPage;
