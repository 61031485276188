import { YoutubeOutlined } from "@ant-design/icons";
import React from "react";

export default function Contact({ vertical = false }) {
  return (
    <div
      className={`contact_icon ${vertical ? "flex flex-col" : "flex "} my-4 gap-[10px] `}
      style={{
        width: "100%",
      }}
    >
      <span className="contact_icon1">
        <a
          href="https://www.facebook.com/Nishanpaus?mibextid=ZbWKwL"
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
      </span>
      {/* <span className="contact_icon2">
        <a
          href="#"
          target="_blank"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-linkedin"></ion-icon>
        </a>
      </span> */}
      <span className="contact_icon3">
        <a
          href="https://www.instagram.com/krishi.sanjal/ "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
      </span>

      <span className="contact_icon5">
        <a
          href="https://x.com/Sanjalkrishi  "
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          {/* <ion-icon name="logo-twitter"></ion-icon> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 16 16"
            className="hover:scale-[1.3] p-[5px] hover:border-full duration-150"
          >
            <path
              fill="currentColor"
              d="M9.333 6.929L14.546 1H13.31L8.783 6.147L5.169 1H1l5.466 7.783L1 15h1.235l4.779-5.436L10.83 15H15zM7.641 8.852l-.554-.776L2.68 1.911h1.898l3.557 4.977l.552.776l4.623 6.47h-1.897z"
              className="w-5 h-5"
            />
          </svg>
        </a>
      </span>
      <span className="youtube-logo">
        <a
          href="https://www.youtube.com/@krishisanjal"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "black" }}
        >
          <YoutubeOutlined
            style={{ fontSize: "35px" }}
            className="hover:scale-[1.3] hover:text-red-800 duration-150"
          />
        </a>
      </span>
    </div>
  );
}
// #contact_icon6 ion-icon:hover {
//   color: red;
//   background-color: whitesmoke;
//   border-radius: 100%;
//   scale: 1.5;
// }
