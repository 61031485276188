import React, { useState } from "react";
import myImg from "./BicharImage.jpg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export default function BicharBlogBox({
  title,
  image,
  id,
  created_date_ad,
  created_date_bs,
}) {
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Link
      to={
        lge === "en"
          ? `/en/story/${created_date_ad.split("T")[0].split("-").join("/")}/${id}/${title}`
          : `/story/${created_date_bs.replace(/-/g, "/")}/${id}`
      }
    >
      <div
        style={{
          // borderBottom: "2px solid #d1d1cf",
          padding: "10px 0px",
          // margin: "0 10px",
        }}
        className="flex items-center h-[100px] w-full cursor-pointer"
        onClick={() => {
          scrollToTop();
        }}
      >
        <img
          src={image}
          alt="image"
          style={{ borderRadius: "5px" }}
          className="mx-3 w-[100px] h-full"
        />
        <span>
          <p
            className=" text-xl text-base my-1 line-clamp-3 text-[rgba(0,0,0,0.7)] hover:text-green-700"
            style={{ fontWeight: "600", lineHeight: "1.5" }}
          >
            {title}
          </p>
          {/* <p
              className="text-xs "
              style={{ fontWeight: "600", lineHeight:'1.5', color: "#6b6b6a" }}
              >
              सोम, फागुन २८, २०८०
              </p> */}
        </span>
      </div>
    </Link>
  );
}
