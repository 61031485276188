// import React from "react";
// import { hydrate, render } from "react-dom"; // Import only hydrate and render
// import "./index.css";
// import App from "./App";
// import { NextUIProvider } from "@nextui-org/react";

// const rootElement = document.getElementById("root");

// // Use hydrate if content is already server-side rendered, otherwise render
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <React.StrictMode>
//       <NextUIProvider>
//         <App />
//       </NextUIProvider>
//     </React.StrictMode>,
//     rootElement
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <NextUIProvider>
//         <App />
//       </NextUIProvider>
//     </React.StrictMode>,
//     rootElement
//   );
// }
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client"; // For React 18
import "./index.css";
import App from "./App";
import { NextUIProvider } from "@nextui-org/react";

const rootElement = document.getElementById("root");

// Use hydrateRoot for SSR/pre-rendered content, otherwise createRoot
if (rootElement.hasChildNodes()) {
  hydrateRoot(
    rootElement,
    <React.StrictMode>
      <NextUIProvider>
        <App />
      </NextUIProvider>
    </React.StrictMode>
  );
} else {
  const root = createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <NextUIProvider>
        <App />
      </NextUIProvider>
    </React.StrictMode>
  );
}
