import React, { useEffect, useState } from "react";
import { Button, Table, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Get, Delete } from "../../../redux/features/API"; // Adjust the import path according to your structure

const columns = (handleDelete) => [
  {
    title: "S.N",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Mobile Number",
    dataIndex: "phone_no",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Address",
    dataIndex: "address",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Lge",
    dataIndex: "lge",
  },
  {
    title: "Action",
    dataIndex: "action",
    render: (_, record) => (
      <div style={{ display: "flex", gap: "8px" }}>
        <Button type="danger" onClick={() => handleDelete(record)}>
          <DeleteOutlined />
        </Button>
      </div>
    ),
  },
];

const ContactTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("Token");
  const headers = { Authorization: `Bearer ${token}` };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await Get({ url: "/contact/contact", headers });

        const formattedData = response.map((item) => ({
          key: item.id,
          name: item.name,
          phone_no: item.phone_no,
          email: item.email,
          address: item.address,
          message: item.message,
          lge: item.language,
        }));
        setDataSource(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Failed to load contact data!");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (record) => {
    Modal.confirm({
      title: "Are you sure you want to delete this contact?",
      content: `Name: ${record.name}`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await Delete({ url: `/contact/contact/${record.key}`, headers });
          setDataSource((prev) =>
            prev.filter((item) => item.key !== record.key)
          );
          message.success("Contact deleted successfully");
        } catch (error) {
          console.error("Error deleting contact:", error);
          message.error("Failed to delete contact!");
        }
      },
    });
  };

  return (
    <>
      <Table
        columns={columns(handleDelete)}
        dataSource={dataSource}
        loading={loading}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default ContactTable;
