import React, { useEffect, useState } from "react";
import AuthorBredCrumb from "../../common/cards/AuthorBredCrumb";
import Ads from "../../common/cards/Ads";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { useNews } from "../../../Dashboard/NewsContext";
import { Link } from "react-router-dom";
import { useTheme } from "../../../Dashboard/ThemeContext";
import FormatNepaliDate from "../../../redux/Specials/FormatNepaliDate";
import { Skeleton } from "@mui/material";

const Hero = ({ lge = "np", order }) => {
  const [news, setNews] = useState(null);
  const [nepaliDate, setNepaliDate] = useState("");
  const [englishDate, setEnglishDate] = useState("");

  const { themeColor } = useTheme();
  const { wholeNews, loading } = useNews();

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // Scroll to top when component mounts
    // scrollToTop();

    const fetchData = async () => {
      try {
        if (wholeNews.length > 0) {
          const filteredResponse = wholeNews.filter(
            (item) => item.breaking_news === true
          );
          setNews(filteredResponse[order]);
        } else {
          setNews(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setNews(null);
      }
    };

    fetchData();
  }, [wholeNews, order]);

  useEffect(() => {
    if (news) {
      if (news.self_date) {
        const englishDateObj = new Date(news.self_date);
        const formattedEnglishDate = `${englishDateObj.getDate()} ${englishDateObj.toLocaleString("default", { month: "long" })} ${englishDateObj.getFullYear()}`;
        setEnglishDate(formattedEnglishDate);
        // setNepaliDate(convertToNepaliDate(news.self_date));
        setNepaliDate(FormatNepaliDate(news.self_date));
      } else if (news.created_date_ad && news.created_date_bs) {
        const englishDateObj = new Date(news.created_date_ad);
        const formattedEnglishDate = `${englishDateObj.getDate()} ${englishDateObj.toLocaleString("default", { month: "long" })} ${englishDateObj.getFullYear()}`;
        setEnglishDate(formattedEnglishDate);
        setNepaliDate(convertToNepaliDate(news.created_date_bs));
      }
    }
  }, [news]);

  const convertToNepaliDate = (dateString) => {
    if (!dateString) return "";
    const nepaliMonths = [
      "बैशाख",
      "ज्येष्ठ",
      "आषाढ",
      "साउन",
      "भाद्र",
      "आश्विन",
      "कार्तिक",
      "मंसिर",
      "पौष",
      "माघ",
      "फागुन",
      "चैत",
    ];
    const [year, month, day] = dateString.split("-").map(Number);
    const nepaliNumbers = (num) => {
      const nepaliDigits = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
      return num
        .toString()
        .split("")
        .map((digit) => nepaliDigits[digit])
        .join("");
    };
    const nepaliMonth = nepaliMonths[month - 1];
    const nepaliDay = nepaliNumbers(day);
    const nepaliYear = nepaliNumbers(year);
    return `${nepaliDay} ${nepaliMonth} ${nepaliYear}`;
  };

  const { news_title, news_sub_title, author_name, category_name, image, id } =
    news || {};

  return (
    <div className="font-mukta mt-[50px]">
      {loading ? (
        <div className="w-full h-[100vh] gap-[20px] flex flex-col justify-center">
          {/* <Spin tip="Loading" size="large" /> */}
          <div className="w-full flex gap-[10px] flex-col items-center">
            <Skeleton variant="rectangular" width="80%" height={60} />
            <Skeleton variant="rectangular" width="30%" height={40} />
            <Skeleton variant="rectangular" width="40%" height={50} />
          </div>
          <Skeleton variant="rectangular" width="90%" height="80vh" />
        </div>
      ) : (
        <>
          {news ? (
            <div
              className={`my-1 flex flex-col items-center gap-[50px] cursor-pointer`}
            >
              <div className="text-center my-[10px] w-full flex flex-col gap-[10px]">
                <Link
                  to={
                    lge === "en"
                      ? `/en/story/${news.created_date_ad.split("T")[0].split("-").join("/")}/${id}/${news_title}`
                      : `/story/${news.created_date_bs.replace(/-/g, "/")}/${id}`
                  }
                  onClick={scrollToTop}
                >
                  <p
                    className="text-3xl sm:text-5xl font-bold max-w-[80%] mx-auto py-1 line-clamp-3 hover:text-[#0c8a30]"
                    style={{ lineHeight: "1.5" }}
                  >
                    {news_title}
                  </p>
                  <p className="max-w-[80%] mx-auto py-1 sm:w-[50%] text-[20px] sm:text-[22px] text-[#6f7370] line-clamp-1">
                    {news_sub_title}
                  </p>
                </Link>
                {author_name && englishDate && nepaliDate && (
                  <AuthorBredCrumb
                    id={author_name}
                    englishDate={englishDate}
                    nepaliDate={nepaliDate}
                    category={category_name}
                    language={lge}
                  />
                )}
              </div>
              <Link
                to={
                  lge === "en"
                    ? `/en/story/${news.created_date_ad.split("T")[0].split("-").join("/")}/${id}/${news_title}`
                    : `/story/${news.created_date_bs.replace(/-/g, "/")}/${id}`
                }
                className="w-full"
                onClick={scrollToTop}
              >
                <div className="w-full flex justify-center">
                  {image ? (
                    <img
                      src={image}
                      className="w-[95%] lg:w-[90%] max-h-[650px]"
                      style={{
                        borderRadius: "10px",
                        border: `2px dotted ${themeColor}`,
                      }}
                      // loading="lazy"
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
              </Link>
            </div>
          ) : (
            <div className="text-center py-4"></div>
          )}
        </>
      )}
    </div>
  );
};

export default Hero;
