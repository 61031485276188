import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navigation from "./Navigation";
import logo from "./logo.png";
import Category from "../User/page/Login/Category";
import SubCategory from "../User/page/Login/SubCategory";
import Authors from "../User/page/Login/Authors";
import Contact from "../User/page/Login/Contact";
import News from "../User/page/Login/News";
import Advertisement from "../User/page/Login/Advertisement";

import FooterDashboard from "../User/page/Login/FooterDashboard";
import Review from "../User/page/Login/Review";
import { useNavigate } from "react-router-dom";
import Members from "../User/page/Login/Members";
import { HelmetProvider, Helmet } from "react-helmet-async";

const PageDashboard = () => {
  const [open, setOpen] = useState(false);
  const [myLink, setMyLink] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const isDashboardRoute = location.pathname.startsWith("/dashboard");
  const isLoginRoute = location.pathname.includes("/dashboard/login");

  useEffect(() => {
    const formattedLink = location.pathname.slice(1); // Remove the first "/"
    const capitalizedLink = formattedLink
      .split("/")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join("/");
    setMyLink(capitalizedLink);
  }, [location.pathname]); // Dependency array to update when the path changes
  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/dashboard/login");
    }
  });
  return (
    <HelmetProvider>
      <div style={{ width: "100%" }} className="flex flex-col items-center ">
        {isDashboardRoute && (
          <>
            <Helmet>
              <link rel="icon" href={logo} type="image/x-icon" />
              <link rel="apple-touch-icon" href={logo} />
              <title>Krishi Sanjal-Dashboard</title>
              <meta
                property="og:description"
                content="Krishi Sanjal Dashboard page"
              />
              <meta
                property="og:title"
                content="KrishiSanjal - Empowering Farmers"
              />

              <meta
                property="og:image"
                content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
              />

              <meta property="fb:app_id" content="1090711906078716" />

              <meta property="og:url" content="https://krishisanjal.com" />
              <meta property="og:type" content="website" />

              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:title"
                content="KrishiSanjal - Empowering Farmers"
              />
              <meta
                name="twitter:description"
                content="Providing agricultural resources to farmers across Nepal."
              />
              <meta
                name="twitter:image"
                content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
              />
            </Helmet>
            {!isLoginRoute && <Navigation open={open} setOpen={setOpen} />}
            <div className="flex w-[97%] md:w-[90%]">
              <div className="w-full min-h-[85vh] bg-green-100">
                <div>
                  <h2 className="text-l px-3  my-5"> {myLink}</h2>{" "}
                  {/* Display the formatted link */}
                </div>
                <div>
                  <Routes>
                    <Route path="" element={<Review />} />
                    <Route path="category" element={<Category />} />
                    <Route path="sub-category" element={<SubCategory />} />
                    {/* <Route path="sub-sub-category" element={<SubSubCategory />} /> */}
                    <Route path="authors" element={<Authors />} />
                    <Route path="members" element={<Members />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="news" element={<News />} />
                    <Route path="advertisement" element={<Advertisement />} />
                    <Route path="footer" element={<FooterDashboard />} />
                    {/* <Route path="review" element={<Review />} /> */}
                    {/* <Route path="notice" element={<Notice />} /> */}
                    {/* <Route path="gallery" element={<Photos />} />
                  <Route path="videos" element={<Videos />} /> */}
                    <Route path="*" element={<div>Route not found</div>} />
                  </Routes>
                </div>
                <footer
                  style={{ minHeight: "5vh", marginTop: "70px" }}
                  className="flex items-center gap-[5px] w-full justify-center"
                >
                  <div
                    style={{
                      height: "70px",
                      width: "100%",
                      // backgroundColor: "#2d5e29",
                      marginTop: "50px",
                      // backgroundColor: themeColor,
                    }}
                    className={`flex flex-wrap  justify-center items-center gap-2 px-2 sm:px-0 `}
                  >
                    <h2 className=" text-l " style={{ color: "#878684" }}>
                      Copyright © 2024 Krishi sanjal
                    </h2>
                    <h2 className=" text-l " style={{ color: "#878684" }}>
                      |
                    </h2>
                    <a
                      href="https://tachyonwave.com/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      className=" text-[#878684] text-l"
                    >
                      Developed by Tachyonwave
                    </a>
                  </div>
                </footer>
              </div>
            </div>
          </>
        )}
      </div>
    </HelmetProvider>
  );
};

export default PageDashboard;
