import React from "react";
import Contact from "../../common/cards/newscard/sideBarComponents/Contact";
import BicharBlog from "../../common/cards/newscard/sideBarComponents/BicharBlog";
import MukhyaSamachar from "../../common/cards/newscard/sideBarComponents/MukhyaSamachar";
import TajaSamachar from "../../common/cards/newscard/sideBarComponents/TajaSamachar";
import Ads from "../../common/cards/Ads";
import TrendingNews from "../../common/cards/newscard/sideBarComponents/TrendingNews";
import SmallAds from "../../common/cards/SmallAds";

const SideContainer = ({ mukhyaShow }) => {
  return (
    <div className="w-full">
      <SmallAds name="H_sidebar_before_followus" />
      <div className="mt-10 w-full">
        {!mukhyaShow && (
          <>
            <h2 className="text-xl font-bold">Follow Us:</h2>
            <Contact />
          </>
        )}
        <SmallAds name="H_sidebar_after_followus" />
        <TajaSamachar />
        <SmallAds name="H_sidebar_after_trending" />
        {mukhyaShow && <MukhyaSamachar />}
        {!mukhyaShow && (
          <div className="flex flex-col gap-[50px] w-full">
            <TrendingNews />
            <SmallAds name="H_sidebar_after_tajakhabar" />
            <BicharBlog />
          </div>
        )}
      </div>
    </div>
  );
};

export default SideContainer;
