import React, { useEffect, useState } from "react";
import Hero from "./Hero";
import Breadcrumb from "../../common/navigation/Breadcrumb";
import Card1 from "./Card1";
import Ads from "../../common/cards/Ads";
import Card2 from "./Card2";
import ContentLayout from "./ContentLayout";
import RoadBlocking from "../../common/cards/RoadBlocking"; // Import your RoadBlocking component
import { useAds } from "../../../Dashboard/AdsContext";
import { useTheme } from "../../../Dashboard/ThemeContext";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
  const { ads } = useAds();
  const { bgColor } = useTheme();
  const [lge, setLge] = useState(
    window.location.pathname.includes("/en") ? "en" : "np"
  );

  return (
    <HelmetProvider>
      <div
        className="w-full flex justify-center "
        style={{ backgroundColor: bgColor }}
      >
        <Helmet>
          <title>KrishiSanjal</title>
          <meta
            property="og:title"
            content="KrishiSanjal - Empowering Farmers"
          />
          <meta
            property="og:description"
            content="KrishiSanjal provides vital agricultural information and resources to farmers in Nepal."
          />
          <meta
            property="og:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />

          <meta property="fb:app_id" content="1090711906078716" />

          <meta property="og:url" content="https://krishisanjal.com" />
          <meta property="og:type" content="website" />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="KrishiSanjal - Empowering Farmers"
          />
          <meta
            name="twitter:description"
            content="Providing agricultural resources to farmers across Nepal."
          />
          <meta
            name="twitter:image"
            content="https://cms.krishisanjal.com/media/author/logo_2.jpg"
          />
        </Helmet>
        <div className="min-h-[400px] w-[97%] sm:w-[90%] mx-auto">
          <RoadBlocking name="H_roadblocking_ads" />

          <Ads name="H_landscape_above_breaking" />
          <Hero lge={lge} order={0} />
          <Ads name="H_landscape_between_breaking" />
          <Hero lge={lge} order={1} />
          <Ads name="H_landscape_after_breaking" />
          <div className="mt-20">
            <Breadcrumb
              addNews={true}
              myWord={lge === "en" ? "Highlights" : "समाचार"}
            />
            <Card1 myWord={lge === "en" ? "News" : "समाचार"} />
          </div>
          <div className="mt-10">
            <Ads name="H_landscape_after_samachar" />
          </div>
          <div className="mt-20">
            <Breadcrumb myWord={lge === "en" ? "Agri-Tech" : "कृषि प्रविधि"} />
            <Card2 myWord={lge === "en" ? "Agri-Tech" : "कृषि प्रविधि"} />
          </div>
          <div className="mt-10">
            <Ads name="H_landscape_krishi_prabidhi" />
          </div>
          <div className="mt-10">
            <ContentLayout mukhyaShow={false} />
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default Home;
